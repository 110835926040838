export enum Icons {
  home = 'home.svg',
  billing = 'billing.svg',
  paymentManagement = 'payment-management.svg',
  subscriptions = 'subscriptions.svg',
  support = 'support.svg',
  settings = 'settings.svg',
  insights = 'dog.svg',
  cyberInsurance = 'security.svg',
  kaseyaCommunity = 'kaseya-community.svg',
  kaseyaUniversity = 'kaseya-university.svg',
  kaseyaSwag = 'shirt-regular.svg',
  kaseyaStore = 'kaseya-store.svg',
  kaseyaEvents = 'kaseya-events.svg',
  partnerProgram = 'handshake.svg',
  kaseya365Upgrade = 'kaseya-365-upgrade.svg',
  ExecutiveReports = 'dashboard.svg',
  ProfessionalServices = 'folder.svg',
  organizationMapping = 'mapping.svg',
  AutomationCenter = 'plug.svg',
}
